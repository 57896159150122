import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Layout from '../components/layout';
import SEO from '../components/seo';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  location,
  children
}) => <Layout location={location} showSidebar={false}>
        <SEO title="About" />
        {children}
    </Layout>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2>{`A place for tried and true recipes`}</h2>
    <p>{`I started Baked by Sun in order to share some of recipes to the food I like to make over and over again. `}</p>
    <p>{`Follow me on instagram `}<a parentName="p" {...{
        "href": "https://www.instagram.com/bakedbysun/"
      }}>{`@bakedbysun`}</a>{` and use the hashtag #bakedbysun`}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      